//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
export default {
  name: "Home",
  data() {
    return {
      productCode: "1003047665",
      scanCode: "",
      storeOption: [],
      currentStore: "",
    };
  },
  created() {
  //  this.verifConfirm();
    console.log(this.currentStore);
    window.document.title = "卡券核销";
    // this.scanClick();
  },
  mounted() {
    window.document.title = "卡券核销";
    this.wxConfig();
  },
  activated() {
    window.document.title = "卡券核销";
  },
  methods: {
    scanClick() {
          //       this.$router.push(
          //   "/verifycoupon/detail?code=1000000976" 
              
          // );
          // return;
      const that = this;
      wx.scanQRCode({
        desc: "扫描货品条码",
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function (res) {
          // 回调
          that.productCode = res.resultStr;
          that.$router.push(
            "/verifycoupon/detail?code=" +
              that.productCode.toUpperCase()
          );
          //that.verifConfirm(that.productCode);
        },
        error: function (res) {
          if (res.errMsg.indexOf("function_not_exist") > 0) {
            alert("版本过低请升级");
          }
        },
      });
    },
    wxConfig(callback) {
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios
        .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getJsSdkConfig", {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then((res) => {
          if (res && res.data && res.data.data) {
            const temp = res.data.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: temp.appId, // 必填，企业微信的corpID
              timestamp: temp.timestamp, // 必填，生成签名的时间戳
              nonceStr: temp.nonceStr, // 必填，生成签名的随机串
              signature: temp.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
              jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
            });
            wx.ready(function () {
              callback && callback();
            });
          } else {
            this.$toast({ message: "调用微信插件失败！", duration: 5000 });
          }
        });
    },

    doVerifCoupon() {
      this.$toast.loading({
        message: "查询中",
        forbidClick: true,
        duration: 0,
      });
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crmcoupon/destroy?ecid=" +
            this.productCode
        )
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.data.code == 0) {
            alert("成功");
          } else {
            this.$toast({ message: res.data.msg, duration: 2000 });
          }
        });
    },
  },
};
